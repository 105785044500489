import React, { Component } from "react"
import { graphql, Link } from "gatsby"

import ContactFooter from '../components/contactfooter'
import ScroolTop from '../components/scrolltop'

import { removePre } from '../util/common'

import SEO from '../components/seo'
import Typed from 'react-typed';
const nextpost = 2;
class WorkTemplate extends Component {
  componentDidMount() {
    const isBrowser = typeof document !== "undefined";
    const AOS = isBrowser ? require("aos") : undefined;

    this.aos = AOS;
    this.aos.init();
} 
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      visible: 0,
      postsPerPage: 4,
      error: false
    };    
  }
  loadMore = (event) => {
    this.setState((prev) => ({
      postsPerPage: prev.postsPerPage + nextpost
    }));
  }
  
    render() {
      const data = this.props.data
      const banner = data.wordpressPage.acf
      const postlen = data.allWordpressWpWork.edges.length;

      var oldString = banner.banner_blink_word;
      var mynewarray=oldString.split(',')

      return (
        <>
            <SEO title="Work" />
            <div className="page-header works-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{banner.banner_content} <Typed strings={mynewarray} typeSpeed={120} backSpeed={50} cursorChar= {'_'} loop /></h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="work-section">
                <div className="container">
                    <div className="work-list">
                    {this.props.data.allWordpressWpWork.edges.slice(this.state.visible, this.state.postsPerPage).map((item, index) => {
                          return (
                            <div className={index % 2 ? 'work-wrap d-flex justify-content-start align-items-end even' : 'work-wrap d-flex justify-content-end align-items-end odd'} key={index}>
                            <div className="work-image">
                            {item.node.featured_media !== null && item.node.featured_media.source_url !== null &&
                               <img src={item.node.featured_media.source_url} alt={item.node.title} />                                  
                           }
                               
                           </div>
                           <div className="work-description">
                               <div className="work-description-inner">
                                   <h2 className="work-title">{item.node.title}</h2>                    
                                   <p>{item.node.acf.work_short_description}</p>
                                   <ul>
                                       {item.node.acf.work_features_list.map((node,index) => (
                                           <li key={index}>{node.work_feature}</li>
                                       ))}
                                   </ul>
                               </div>
                               <Link to={`/${removePre(item.node.link)}`} className="work-btn">
                               Read On</Link>
                               
                           </div>
                       </div>
                        );             
                      })}
                      <div className="work-lm-btn">
                      <button onClick={this.loadMore} type="button"  className={this.state.postsPerPage > postlen ? 'hide-lm-btn' : 'show-lm-btn'} >Load more</button>
                      </div>
                    </div>
                </div>
            </section>
            <ContactFooter />
            <ScroolTop />
        </>
        )
  }

}


export default WorkTemplate

export const pageQuery = graphql`
query($skip: Int! , $limit: Int!) {
    wordpressPage(wordpress_id: {eq: 17}) {
        acf {
            banner_heading
            banner_content
            banner_blink_word
        }
      }
    allWordpressWpWork(limit: $limit, skip: $skip, sort: {order: DESC, fields: date}) {
        edges {
          node {
            id
            title
            link
            featured_media {
              source_url
            }
            acf {
                work_top_section {
                  work_right_content {
                    work_category {
                      name
                    }
                  }
                }
                work_features_list {
                  work_feature
                }
                work_short_description
              }
          }
        }
    }   
  
}
`